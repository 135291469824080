/* Navbar.css */

.navbar {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    margin: 0;
    /* background-color: #333; */
    background: linear-gradient(rgba(0, 0, 0, 0.60), rgba(0, 0, 0, 0.60));
    color: white;
    position: fixed;
    /* align-items: center; */
    width: 100%;
    z-index: 5;
  }
  
  .desktop {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .menu {
    list-style: none;
    display: flex;
  }
  
  .mobile {
    display: none; /* Initially hide for desktop */
  }

  .logo-img {
    height: 40px;
    font-weight: 100;
    max-width: max-content;
    /* padding-right: 15px; */
  }

  .logo-img g {
    fill: #fff;
    font-weight: 100;
  }

  .mobile-lang-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .contact-div {
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding-bottom: 5px; */
  }

  .to-contact {
    padding: 5px 10px;
    margin: 0 10px;
    border-radius: 8px;
    border: none;
    /* background: linear-gradient(rgba(99, 158, 255, 0.6), rgba(3, 60, 250, 0.60)); */
    color: white;
    box-shadow: 1px 1px 5px #fff;
    text-align: center;
    font-size: 14px;
    align-items: center;
    justify-content: center;
  }

.to-contact:hover {
  /* background: linear-gradient(rgba(250, 252, 255, 0.9),rgba(9, 66, 255, 0.6)); */
  box-shadow: 2px 2px 20px #fff;
  transform: scale(1.05);
}
  
 @media (max-width: 768px) {
    .to-contact {
      margin: 0;
    }
    .desktop {
      display: none; /* Hide desktop menu on mobile */
    }
  
    .mobile {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100vw;
    }
  
    .hamburger-menu {
      font-size: 1.5rem;
      cursor: pointer;
      z-index: 1;
    }

    .logo-img {
      padding: 0;
      margin: 0;
    }
  } 

  .mobile-menu {
    margin: 10px;
    padding-bottom: 20px;
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    height: max-content;
    background-color: rgb(209, 209, 209);
    color: black;
    display: none;
    flex-direction: column;
    /* justify-content: end; */
    z-index: 1;
    overflow-y: auto;
    border-radius: 8px;
    gap: 0;
  }
  
  .menu-header {
    display: flex;
    justify-content: space-between;
    /* width: 100vw; */
    padding-top: 5px;
  }
  
  .cancel-button {
    background-color: transparent;
    border: none;
    color: black;
    font-size: 2.3rem;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 50vw;
    height: max-content;
    display: flex;
    justify-content: end;
    padding: 0;
    padding-right: 20px;
    margin: 0;
  }
  
  .cancel-button:hover {
    text-decoration: underline;
  }

  .ul_style {
    /* position: absolute; */
    display: flex;
    color: black;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    text-align: center;
    gap: 20px;
  }
  
  @media (max-width: 768px) {
    .desktop {
      display: none;
    }
  
    .hamburger-menu {
      font-size: 2rem;
      cursor: pointer;
    }
  
    .mobile-menu {
      display: flex;
      flex-direction: column;
    }

    .ul_style .item {
      color: black;
      font-size: 18px;
      border-bottom: #d4efff 1px solid;
      padding: 5px;
    }

    .ul_style .select {
      color: #333;
    }

    .item {
      font-size: 12px;
      letter-spacing: 0.03em;
    }
  }

.select {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 19px;
}

.select option {
    background-color: rgba(39,65,89, 0.7);
    color: white;
}

.items {
  display: flex;
  flex-direction: row;
  
  gap: 15px;
  padding: 0 60px;
}

.logo {
  color: #fff;
  /* padding: 0 60px; */
  display: flex;
  font-size: 20px;
  font-weight: bold;
  align-items: center;
  justify-content: center ;
  width: max-content;
}

.item {
  text-decoration: none;
  color: white;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.07em;
  padding: 10px;
  cursor: pointer;
}

.item:hover {
  border-bottom: #15a3f5 1px solid;
}

* {
  padding: 0;
  margin: 0;
}