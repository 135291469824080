body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'San Francisco';
  font-weight: 400;
  src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.album_skeleton {
  background-color: #ededed;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #ededed;
  animation: loading 1s ease-in-out infinite; /* Use the defined keyframe animation */
  background-position-x: 180%;
  background-size: 200% 100%;
  height: 220px;
  width: 350px;
  border-radius: 15px;
}

.plan_skeleton {
  background-color: #d8d6d6;
  background: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0) 40%,
    rgba(255, 255, 255, .5) 50%,
    rgba(255, 255, 255, 0) 60%
  ) #d8d6d6;
  animation: loading 1s ease-in-out infinite; /* Use the defined keyframe animation */
  background-position-x: 180%;
  background-size: 200% 100%;
  height: 50vh;
  width: 50vw;
  border-radius: 0px;
}

@keyframes loading {
  0% {
      background-position-x: 100%;
  }
  100% {
      background-position-x: -100%;
  }
}

.loader_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  z-index: 10;
}

.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  inset: 8px;
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: rgb(109, 70, 250);
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
    0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
    50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
    75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}

.select_div {
    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
    padding-top: 5px;
    /* right: 0; */
}

.selected_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding-top: 5px;
  /* width: 50vw; */
  /* padding: 0 13px; */
}

.option_div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* width: 50vw; */
  padding: 0 13px;
  cursor: pointer;
}

.option_div:hover {
  background-color: rgb(192, 192, 250);
}

.option_div p {
  font-size: 16px;
  font-weight: 500;
}

.select_det {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  margin-top: 40px;
  margin-right: 15px;
  background-color: #4b5b6a85;
  /* position: static; */
}

.select_div p {
  font-size: 16px;
}

.option_img {
    height: 20px;
    width: 20px;
    padding: 0;
    margin: 0;
}

@media (max-width: 640px) {
  .select_det {
    margin-right: 55px;
    margin-top: 150px;
  }

  .plan_skeleton {
    width: 100vw;
    height: 25vh;
  }
}