.container {
    padding: 160px;
    display: flex;
    color: white;
    font-weight: bold;
    background-color: transparent;
    background: url('../../public/2.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 100px;
}

.abc {
    display: flex;
    height: 100vh;
    width: 100vw;
    /* justify-self: unset; */
    justify-content: center;
    align-items: center;
}

.mark_container {
    width: 100vw;
    height: 10vh;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0px;
    /* margin-left: 165px; */
    padding-left: 9.1vw;
    /* min-width: 100vw; */
    padding-top: 15.2vh;
    z-index: 5;
    position: absolute;
}

.mark {
    padding: 5px;
    /* background: #d3d3d344; */
    border-radius: 50px;
    /* border: 1px solid rgba(255, 0, 0, 0.15); */
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff65;
    /* color: red; */
    font-size: 12px;
}

.flag {
    width: 35px;
    height: 35px;
}

.select {
    z-index: 5;
}

.button_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    text-decoration: none;
    padding: 30px;
    z-index: 5;
}


.label {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;
    gap: 50px;
}

.label p {
    display: inline-flex;
    width: 50vw;
    font-weight: 500;
    font-size: 17px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    background-size: contain;
    text-shadow: 0 0 2px #000; /* horizontal-offset vertical-offset 'blur' colour */
    -moz-text-shadow: 0 0 2px #000;
    -webkit-text-shadow: 0 0 2px #000;
    width: max-content;
}

.logo {
    padding-top: 300px;
    font-size: 65px;
    background: linear-gradient(to bottom, #297FFF, #ffffffe5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: move 5s infinite ease alternate;
}

.title {
    align-items: start;
    text-align: start;
}

@keyframes move {
    from {
        transform: translateY(-5px);
    } 
    to {
        transform: translateY(0) scale(1.03);
    }
}

.button_outlined {
    padding: 16px 55px;
    background-color: transparent;
    border-radius: 8px;
    border: 2px rgba(39,65,89, 0.5) solid;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    width: max-content;
    color: #fff;
    text-shadow: 1px 1px #00000080;
    text-decoration: none;
    text-align: center;
}

.button {
    padding: 16px 50px;
    background-color: rgba(39,65,89, 0.7);
    /* background-color: #9b9b9b; */
    border-radius: 8px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    width: max-content;
    color: #fff;
    text-decoration: none;
    text-align: center;
}

.button:hover {
    background-color: rgba(39,65,89, 1);
}

.button_second:hover {
    background-color: rgba(39,65,89, 1);
}

.button_second {
    padding: 16px 55px;
    background-color: rgba(39,65,89, 0.7);
    border-radius: 8px;
    border: none;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    width: max-content;
    color: #fff;
    text-decoration: none;
    text-align: center;
}

.button_underlined {
    padding: 15px 25px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    width: max-content;
    color: #fff;
    text-shadow: 2px 2px #66a0f880;
    border-bottom: 3px #66a1f8 solid;
    text-decoration: none;
}

.row {
    display: flex;
    /* flex-direction: row; */
    overflow-x: scroll;
    /* overflow-y: hidden; */
    /* white-space: nowrap; */
    gap: 15px;
    scroll-behavior: smooth;
    &::-webkit-scrollbar {
        display: none;
      }
}

.row-scrolling {
    transition: scroll-behavior 1s;
  }

.scroll_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.scroll_button_left {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 40px;
    cursor: pointer;
    z-index: 1;
    left: 0;
}

.scroll_button_right {
    background-color: transparent;
    color: #fff;
    border: none;
    font-size: 40px;
    cursor: pointer;
    z-index: 1;
    right: 0;
}

@media only screen and (max-width: 900px) and (max-width: 600px), (max-width: 1100px) {
    .container {
        justify-content: center;
        align-items: center;
        text-align: center;
        padding: 0;
        margin: 0;
        background-size: cover;
        background-position: center;
        min-height: 100vh;
        height: max-content;
        padding: 80px;
    }

    .mark_container {
        padding-top: 3vh;
        margin-left: 120px;
        align-items: start;
    }

    .mark {
        font-size: 8px;
    }

    .logo {
        /* padding-top: 100px; */
        font-size: 40px;
        inline-size: 80vw;
        overflow-wrap: break-word;
    }

    .label p {
        margin: 1px;
        width: 90vw;
    }

    .button_container {
        padding: 0;
        padding-bottom: 50px;
    }
  }