body.active-modal {
    overflow-y: hidden;
}

.container {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 10px;
    gap: 10px;
    justify-content: start;
}

.container_skeleton {
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    padding: 10px;
    gap: 10px;
    justify-content: start;
}

.img {
    width: 18vw;
    height: 100%;
    border-radius: 8px;
}

.img_pop {
    width: max-content;
    height: 60vh;
    border-radius: 8px;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    /* background: #f1f1f1; */
    background: transparent;
    padding: 0;
    border-radius: 15px;
    max-width: max-content;
    min-width: max-content;
}

.close_modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    border: none;
    font-size: 25px;
    font-weight: bold;
    color: white;
    background: transparent;
}

.article {
    /* height: 100%; */
    /* width: 100vw; */
    position: relative;
    overflow: hidden;
}

.picture {
    object-fit: cover;
    width: 100vw;
    height: 500px;
    filter: contrast(1.35) brightness(0.4);
}

.header_container {
    display: flex;
    flex-direction: column;
}

.button {
    padding-top: 100px;
    font-size: 20px;
    text-decoration: none;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    text-shadow: 0 0 25px black;
}

.icon {
    font-size: 18px;
}

.header {
    font-size: 50px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    text-shadow: 0 0 25px black;
}

.arrow {
    font-weight: bold;
    font-size: 40px; /* Adjust the font size as needed */
  }

@media only screen and (max-width: 600px) {
    .container {
        grid-template-columns: auto;
        gap: 15px;
        justify-content: center;
    }

    .img {
        width: 90vw;
    }

    .img_pop {
        width: 80vw;
        height: 50vh;
        border-radius: 18px;
    }
  }