.container {
    display: grid;
    grid-template-columns: auto auto;
    gap: 1px;
}

.article {
    height: 50vh;
    width: 50vw;
    position: relative;
    overflow: hidden;
}

.picture:hover{
    filter: contrast(1.35) brightness(0.8);
}

.picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
    filter: contrast(1.35) brightness(0.4);
}

.header {
    font-size: 50px;
    color: white;
    text-align: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    text-shadow: 0 0 25px black;
}

@media only screen and (max-width: 600px) {
    .container {
        padding-top: 60px;
        grid-template-columns: auto;
        /* gap: 15px; */
    }
    .article {
        width: 100vw;
        height: 25vh;
        /* height: max-content; */
    }

    .header {
        font-size: 25px;
    }
  }