p {
    font-size: 18px;
}

.container {
    background-color: #dceaf5;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    gap: 60px;
    padding: 20px;
    padding-top: 100px;
}

.descripiton_container {
    display: grid;
    grid-template-columns: auto auto;
    width: 90vw;
    justify-content: start;
    gap: 100px;
    padding: 20px;
}

.description {
    width: 40vw;
    background-color: #ffffff75;
    padding: 10px;
    border-radius: 18px;
}

.price_container {
    background: rgba(107, 166, 255, 0.6);
    border-radius: 15px;
}

.price_header {
    display: flex;
    justify-content: space-around;
    align-items: start;
    gap: 10px;
    width: 18vw;
    border-radius: 15px;
    background-color: #6BA6FF;
    padding: 15px 30px;
    color: #fff;
}

.price_label h2 {
    font-size: 50px;
    color: #fff;
    padding: 0;
    margin: 0;
}

.price_label p {
    color: #e1e1e1;
    margin: 0;
    padding: 0;
}

.plan_suggesion {
    padding: 20px;
    font-size: 18px;
}

.plan_container {
    display: flex;
    justify-content: start;
    width: 90vw;
    gap: 100px;
}

.plan_container img {
    width: 40vw;
    height: 100%;
    border-radius: 20px;
}

.plan_container p {
    text-align: start;
    font-size: 20px;
    font-weight: 600;
}

.album_container {
    display: flex;
    width: 90vw;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    overflow-x: scroll;
    padding: 10px 0;
}

.photo_album {
    display: flex;
    gap: 10px;
    /* overflow-x: scroll; */
}

.album_title {
    color: black;
    text-decoration: none;
    align-items: center;
    display: flex;
    gap: 10px;
}

.album_title:hover {
    transform: scale(1.1);
    color: #2172eb;
    font-weight: 600;
}

.album_title .right_arrow {
    font-size: 16px;
}

.album {
    display: flex;
    gap: 40px;
}

.album .img {
    width: 21vw;
    border-radius: 8px;
}

.comment {
    display: flex;
    flex-direction: column;
    /* gap: 30px; */
    justify-content: start;
}

.comment_container {
    display: grid;
    /* flex-direction: column; */
    grid-template-columns: auto auto;
    /* overflow-x: scroll; */
    gap: 30px;
    color: white;
    /* max-width: 90vw; */
    justify-content: start;
    align-items: start;
    /* background-color: #fff; */
    width: 90vw;
}

.comment_border {
    display: flex;
    flex-direction: column;
    padding: 10px 15px;
    background-color: #ffffff3b;
    color: black;
    gap: 20px;
    align-items: start;
    border-radius: 15px;
    width: 40vw;
}

.comment_border img {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border-image: round;
}

.inputs {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: min-content;
    align-items: start;
    /* background-color: #ffffff1f; */
    border-radius: 15px;
    /* box-shadow: 1px 1px 1px rgba(107, 166, 255, 0.1); */
    /* border: 1px solid rgba(107, 166, 255, 0.1); */
}

.inputs .info {
    display: flex;
    grid-template-columns: auto auto;
    padding: 0;
    margin: 0;
    /* background-color: #333; */
    flex-direction: row;
    align-items: start;
    justify-content: start;
    gap: 30px;
}

.inputs input {
    padding: 10px;
    border: none;
    border-radius: 0;
    /* background-color: transparent; */
    background-color: #ffffff4f;
    border-bottom: 1px solid #333;
    width: 200px;
}

.inputs div {
    display: flex;
    flex-direction: column;
    padding: 10px 0;
    align-items: start;
    /* justify-content: center; */
}

.nameinfo {
    margin-top: 17px;
}

.custom_file_input {
    display: none;
}

.inputs .textarea {
    width: 350px;
    height: 50px;
    padding: 5px 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 15px;
    background-color: #f8f8f8;
    resize: none;
}

.inputs .profile_photo {
    width: 50px;
    height: 50px;
}

.button_container {
    width: 40vw;
    align-items: center;
}

.commentButton {
    /* display: flex; */
    /* margin: 10px 0px; */
    margin-bottom: 60px;
    height: max-content;
    width: 200px;
    padding: 10px 30px;
    border: none;
    border-bottom: 1px solid rgba(107, 166, 255, 0.6);
    background-color: #fff;
    border-radius: 5px;
    font-size: 16px;
}

.commentButton:hover {
    border-bottom: 1px solid rgba(107, 166, 255);
    transform: scale(1.05);
}

.message_container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding: 0;
    margin: 0;
}

.message_container p {
    font-size: 18px;
    width: 35vw;
}

.userinfo {
    margin-top: 0px;
    border-top: 1px #fff solid;
    overflow-wrap: break-word;
    /* font-size: 12px; */
    /* width: 50vw; */
}

.userinfo p {
    padding-top: 10px;
    width: 38vw;
    font-size: 14px;
}

.message_container h3 {
    font-size: 16px;
    padding: 0;
    margin: 0;
}

.message_container h4 {
    font-size: 14px;
    color: #939393;
    padding: 0;
    margin: 0;
    /* padding-bottom: 10px; */
}

@media only screen and (max-width: 600px) {
    .descripiton_container {
        grid-template-columns: auto;
        padding: 0;
        gap: 40px;
    }
    .description {
        width: 90vw;
        padding: 10px;
        border-bottom: 1px solid #2172eb;
        padding-bottom: 30px;
    }
    .price_container {
        width: max-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .price_header {
        /* width: 70vw; */
        width: 90vw;
    } 

    .plan_container {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .plan_container img {
        width: 90vw;
    }

    .section {
        padding-bottom: 20px;
        font-size: 18px;
        font-weight: 550;
    }

    .album {
        display: grid;
        grid-template-columns: auto;
        gap: 40px;
    }

    .album .img {
        width: 90vw;
        border-radius: 8px;
    }

    .comment {
        justify-content: center;
        align-items: end;
    }

    .comment p {
        /* padding-left: 15px; */
        align-items: end;
        justify-content: start;
        text-align: start;
        width: 100vw;
        padding-left: 50px;
    }
    .comment_container {
        display: flex;
        flex-direction: column;
        /* overflow-x: scroll; */
        /* grid-template-columns: auto; */
        gap: 30px;
        color: white;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }

    .comment_border {
        width: 90vw;
        border-radius: 8px;
        margin-left: 50px;
        align-self: center;
        justify-content: center;
    }

    .comment_border img {
        width: 70px;
        height: 70px;
        border-radius: 100%;
        border-image: round;
    }

    .message_container p {
        padding: 5px;
        font-size: 12px;
        width: 50vw;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .userinfo {
        overflow-wrap: break-word;
    }

    .userinfo p {
        width: 80vw;
        text-align: start;
        font-size: 14px;
    }

    .inputs .textarea {
        width: 85vw;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        width: 90vw;
        /* grid-template-columns: auto; */
        align-items: center;
        justify-content: center;
        gap: 0px;
    }

    .button_container {
        display: flex;
        align-items: center;
        justify-content: center;
        /* width: 90vw; */
    }

    .commentButton {
        width: 60vw;
        /* border-bottom: 1px solid #333; */
        border: 1px solid rgba(107, 166, 255, 0.5);
        align-items: center;
        margin: 0;
    }

    .inputs .info {
        width: 90vw;
    }

    .inputs input {
        /* background-color: #333; */
        width: 40vw;
        
    }
  }