.container {
    display: flex;
    /* position: absolute; */
    position: fixed;
    gap: 30px;
    bottom: 0;
    right: 0;
    justify-content: end;
    padding: 30px;
    max-width: 95vw;
    height: fit-content;
    /* width: 100vw; */
}

.icons {
    display: flex;
    max-width: 95vw;
    gap: 20px;
}

.icon {
    width: 50px;
    height: 50px;
}

.button {
    background: transparent;
    border: none;
}

@media only screen and (max-width: 600px) {
    .icon {
        width: 40px;
        height: 40px;
    }
}