.unit {
    background-repeat: no-repeat;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.2)), url('../../public/3.jpg') no-repeat center center;
    background-size: 100vw 100vh;
    min-height: 100vh;
    padding-top: 100px;
    /* min-height: max-content; */
    width: 100%; 
}

.container {
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 50px;
    justify-content: center;
    align-items: start;
    z-index: -1;
    text-align: center;
    height: max-content;
}

.container_skeleton {
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 50px;
    justify-content: center;
    align-items: start;
    height: max-content;
}

.item {
    width: max-content;
    height: max-content;
    padding: 0;
    margin: 10px;
    background-color: rgba(39,65,89, 0.7);
    border-radius: 8px;
    color: #fff;
}

.logo {
    color: #fff;
    padding: 20px;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.img {
    width: 100%;
    height: 180px;
    border-radius: 8px;
}


.title_container {
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.button {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
}

@media only screen and (max-width: 600px) {
    .unit {
        background-size: cover;
        height: max-content;
    }

    .container {
        grid-template-columns: auto;
        gap: 15px;
        justify-content: center;
    }

    .container_skeleton {
        grid-template-columns: auto;
        gap: 15px;
        justify-content: center;
    }

    .img {
        width: 80vw;
    }
  }