.unit {
    padding: 100px;
    min-height: 100vh;
    align-items: center;
}

.container {
    display: flex;
    justify-content: center;
    align-items: start;
    gap: 100px;
    padding: 50px 0;
}

.items .contact_icon {
    width: 35vw;
    height: auto;
}

.items {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.items .contact {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact input {
    background-color: none;
    border: none;
    border-bottom: 1px solid rgb(199, 199, 199);
    padding: 10px;
    /* width: 20vw; */
}

.contact input:placeholder-shown {
    font-size: 14px;
}

.contact textarea:placeholder-shown {
    padding: 10px 5px;
    font-size: 14px;
}

.contact textarea {
    border-radius: 8px;
    border: 1px solid rgb(199, 199, 199);
}

.button {
    margin-top: 10px;
    border-radius: 10px;
    padding: 10px;
    border: none;
    background-color: none;
    font-size: 18px;
    font-weight: 600;
    color: white;
    background: linear-gradient(rgba(99, 158, 255, 1), rgba(3, 60, 250, 0.2));
}

.button:hover {
    background: linear-gradient(rgba(196, 217, 248, 0.9),rgba(9, 66, 255, 0.6));
    transform: scale(1.1);
}

.icons {
    display: flex;
    gap: 20px;
}

.icons img {
    width: 50px;
    height: 50px;
}

.popup {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
}

.popup_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
    background-color: #fff;
    border-radius: 15px;
    padding: 50px;
}

.popup_content img {
    height: 25vh;
    width: auto;
}

.popup_content h2 {
    color: green;
    font-size: 30px;
}

.popup_content p {
    color: gray;
}

.popup_button {
    background: linear-gradient(rgba(0, 128, 0, 0.7),rgba(107, 200, 57, 0.7));
    color: #fff;
    padding: 10px 100px;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
}

.popup_button:hover {
    background: linear-gradient(rgba(0, 128, 0, 1),rgba(107, 200, 57, 0.9));
    transform: scale(1.05);
    font-weight: 600;
}

.about {
    /* width: 40vw; */
    /* background-color: #333; */
    /* overflow-y: scroll; */
    /* height: 30vh; */
    background-color: #f3f3f3;
    padding: 10px;
    border-radius: 15px;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .items .contact_icon {
        width: 95vw;
        /* display: none; */
    }

    .contact input {
        width: 90vw;
    }
    
    .popup_content img {
        height: 15vh;
        width: auto;
    }
}